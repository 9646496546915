@media (min-width: 576px) {
    .h-sm-100 {
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .h-md-100 {
        height: 100vh;
    }
}

:root {
    /* BASE COLORS (no customer changes here!) */
    /* -------- Backgrounds ------ */
    --base-bg-color-0: transparent;
    --base-bg-color-1: #fff;
    /* weiss */
    --base-bg-color-2: #f8f9fa;
    /* light grey */
    --base-bg-color-3: #242424;
    /* dark grey */
    --base-bg-color-4: #424242;
    /* medium dark grey */
    --base-bg-color-5: #bbbbbb;
    /* medium light grey */
    --base-bg-color-6: #888888;
    /* CabinClass Y */
    --base-bg-color-7: #b8860b;
    /* CabinClass P */
    --base-bg-color-8: #038103;
    /* CabinClass C */
    --base-bg-color-9: #8b253b;
    /* CabinClass F */
    --base-bg-color-10: rgba(215, 215, 215, .25);
    /* very light and semi-transparent grey */
    --base-bg-color-11: #949494;
    /* Ancillary */
    /* -------- Fonts ----------- */
    --base-font-color-1: #242424;
    --base-font-color-2: #cdcdcd;
    --base-font-color-3: #fff;
    --base-font-color-4: #bbb;
    --base-font-color-5: #757575;
    /* Material Design Icons */
    --base-font-color-6: #ff0000;
    /* Red, e.g. Next Day Arrival */
    --base-font-color-7: #2196f3;
    /* e.g. traveltime */
    --base-font-color-8: #0ec051;
    /* Flight with NO STOPS */
    --base-font-color-9: #eabd18;
    /* Flight with 1 STOP */
    --base-font-color-10: #f63636;
    /* Flight with 2+ STOPS */
    --base-font-color-11: #949494;
    /* Ancillary Icons */
    --base-font-color-12: #bb0000;
    /* Ancillary Price */
    --base-font-color-13: #495057;
    /* Bookingmanager */
    /* -------- Buttons ---------*/
    --base-button-color-1: #868e96;
    /* btn disabled */
    --base-button-color-2: #e6e6e6;
    /* btn cancel */
    --base-button-color-5: #d2d2d2;
    /* btn cancel hover */

    /* THEME COLORS (these can be changed) */
    /* -------- Backgrounds ------ */
    --theme-bg-color-1: #fff;
    --theme-bg-color-2: #2196f3;
    /* dark blue, Primary color */
    --theme-bg-color-3: #64b5f6;
    /* medium dark blue */
    --theme-bg-color-4: #bbdefb;
    /* medium blue */
    --theme-bg-color-5: #e3f2fd;
    /* medium light blue */
    --theme-bg-color-6: #f3f9fe;
    /* light blue, Secondary color */
    --theme-bg-color-7: #ffc107;
    /* High contrast */
    --theme-bg-color-8: rgba(255, 193, 7, 0.1);
    /* Light contrast */
    --theme-bg-color-9: rgba(255, 193, 7, 0.3);
    /* Medium contrast */
    --theme-bg-color-10: #cddae4;
    /* Modal segment header */
    --theme-bg-color-11: #242424;
    --theme-bg-color-12: #dee2e6;
    /* blue-grey */
    --theme-bg-color-13: rgba(82, 162, 222, .1);
    /* light blue-grey */
    --theme-bg-color-14: #f3f1ef;
    /* Body background */
    --theme-bg-color-15: #fbf5e9;
    /* Connection header when flight times are being displayed as well */
    /* -------- Fonts ----------- */
    --theme-font-color-1: #242424;
    --theme-font-color-2: #fff;
    --theme-font-color-3: #ffc107;
    /* Text contrast */
    --theme-font-color-4: #868e96;
    /* disabled */
    /* -------- Buttons ---------*/
    --theme-button-color-1: #2196f3;
    /* btn primary */
    --theme-button-color-2: #64b5f6;
    /* btn primary hover */
    --theme-button-color-3: #cdcdcd;
    /* btn primary disabled */
    --theme-button-color-4: #90caf9;
    /* btn secondary */
    --theme-button-color-5: #90caf9;
    /* btn secondary hover */
}

/* **************************
****************************
General Styles
****************************
************************** */
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'); */



*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
    border-radius: 4px;
    background: rgba(190, 190, 190, 0.2);
    border: 1px solid #ccc;
}

*::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

*::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
    border-radius: 4px;
    background: #fff;
    border: 1px solid #aaa;
}

*::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:active {
    background: rgba(210, 210, 210, 0.1);
}



.table-inactive td {
    background-color: #ececec;
    color: #b9b9b9;
}

html {
    background: linear-gradient(rgba(220, 220, 220, 0.5), rgba(220, 220, 220, 0.5)), url(images/BGSky.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    scroll-behavior: smooth;
}

body {
    /*
    background-color: var(--theme-bg-color-14, #f3f1ef);
    */
    background: none;
    /* height: 100%;
    width: 100%; */
    font-family: 'Roboto', sans-serif;
    /* font-weight: 300; */
    font-size: 1rem;
    color: var(--theme-font-color-1, #242424);
}


input,
button,
select,
textarea {
    font-family: 'Roboto', sans-serif;
}

input:invalid:focus,
select:invalid:focus,
textarea:invalid:focus,
input.invalid,
select.invalid,
textarea.invalid {
    border: solid 1px #dc3545;
}

input:valid,
select:valid,
textarea:valid {
    /* border: 1px solid #555555; */
}

.colorRed {
    color: red;
}

.custmCss.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #555555;
}

.custmCss .input-group-text {
    border: 1px solid #555555 !important;
}

.activeTab {
    background: #e3f2fd;
}

.container {
    max-width: 540px;
}

.content-wrapper {
    padding: 0.75rem;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 3;
}

.rw-multiselect-input {
    border: none !important;
}

.text-black {
    color: black !important;
}

.center-container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
}

.center-content {
    width: 80%;
    height: 80vh;
}


.table-sm td,
.table-sm th {
    padding: .1rem .1rem;
    font-size: 0.75rem;
}

.textEllipseFare {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.discountContainer {
    display: flex !important;
}

.discountContainer .rw-dropdown-list-input {
    width: 96% !important;
}

.smartCalenderDiv td.css-1y3chau {
    padding: 3px !important;
    text-align: center;
}

.smartCalenderDiv {
    min-height: 340px !important;
}

.rebookAddBtn {
    width: 120px !important;
}

/* .airlinesAdmin .rw-popup-container{
    display: none !important;
} */

.airlinesAdmin>div {
    min-height: auto !important;
    height: auto !important;
    overflow: unset !important;
}

/* .airlinesAdmin > div > span{
    display: none;
} */

#epic-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: transparent url(images/fullscreen-overlay-pattern.png) repeat top left;
    z-index: 2;
}

.toast {
    z-index: 99;
}

.toast.show {
    opacity: 0.9;
}

.toast.bg-success .toast-header {
    color: #6c757d;
    background-color: rgba(255, 255, 255, .65);
}

.toast.bg-success .toast-body {
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
}



.clickme {
    cursor: pointer !important;
}

#userinfo {
    position: fixed;
    width: 100%;
    z-index: 4;
    background-color: var(--theme-bg-color-1, #fff);
}

#userinfo>div {
    padding: 2px 0 0 0;
}

#availfaresexpire {
    left: 0;
    position: fixed;
    background-color: var(--theme-bg-color-1, #fff);
    border-color: #000;
    border-style: dashed;
    border-width: 1px;
    z-index: 4;
}


#userinfo #copyTIDToClipboard,
#userinfo #copyTIDToClipboard i {
    color: #dedede;
    cursor: default;
}

#userinfo #copyTIDToClipboard.hasTID,
#userinfo #copyTIDToClipboard.hasTID i {
    color: var(--theme-font-color-1, #242424);
    cursor: pointer;
}


body.screen-login {
    padding-bottom: 0;
}



#screen-query,
#screen-farebooking,
#screen-bookingconfirmation,
#screen-faredisplay,
#screen-bookingmanager {
    margin-top: 1.7rem;
}

#screen-query {
    margin-bottom: 5rem;
}





a {
    outline: none;
}

.hidden {
    display: none;
}

pre {
    margin-bottom: -4.5px !important;
    -webkit-margin-before: -4.5px;
    font-size: inherit;
    color: inherit;
}

.nowrap {
    white-space: nowrap;
}

.overflow-auto {
    overflow-y: auto;
}

.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.boxShadow-light {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
}

.boxShadow-strong {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15) !important;
}

.font-weight-bold {
    font-weight: 600 !important;
}

.text-muted {
    opacity: 0.5;
}

.text-contrast {
    color: var(--theme-font-color-3, #ffc107);
}

.content-divider {
    display: block;
    border-bottom: solid 1px #cdcdcd;
    margin: 1rem 0;
}

.content-divider-dark {
    display: block;
    border-bottom: solid 1px #acacac;
    margin: 1rem 0;
}

.newD .input-group-text {
    padding: 0px 25px !important;
}

.alert ul {
    margin-bottom: 0;
}

.vertical-center {
    min-height: 100%;
    /* Fallback for vh unit */
    min-height: 100vh;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}


.cursor-pointer {
    cursor: pointer;
}

.sidebar .badge {
    color: unset !important;
}

.sidebar .nav-item .nav-link {
    color: unset !important;
}

/* label {
    font-weight: 300;
    margin-bottom: .25rem;
}

.badge {
    font-weight: 400;
}

.form-select {
    font-weight: 300;
    height: inherit;
    width: auto;
}

.form-select option {
    font-weight: 300;
} */

.form-select-sm,
.input-group-sm>.form-select {
    font-size: 0.9rem;
    height: inherit;
}

.input-group .form-select:not(:first-child),
.input-group .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.form-control {
    display: block;
    width: 100%;
    /* height: calc(2.25rem + 2px); */
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.1rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
}

#screen-login .form-control,
.screen-query .form-control {
    height: calc(2.75rem + 2px);
}

.form-control-sm,
.input-group-sm>.form-control {
    /* padding: 1rem 1.75rem 1rem .75rem; */
    font-size: 0.9rem;
}

.input-group-sm>.input-group-append>.btn {
    padding: 3px 6px;
}

.form-control:disabled,
.form-control[readonly],
.form-select:disabled {
    background-color: rgba(235, 235, 235, 0.5);
    opacity: 1;
}

#screen-quickaccess {
    padding: 50px 0;
    margin-top: 50px;
}


#screen-query .alert ul {
    margin: 0;
    padding: 0 0 0 1rem;
}


.alert {
    /* --bs-alert-bg: transparent; */
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: 0px;
}

.hoverUpsellIcon {
    padding: 8px 10px;
    min-height: 20px;
}

.upSellCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Darker shadow on hover */
}

.upSellCard {
    background-color: white;
    /* Card background color */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Shadow effect */
    transition: box-shadow 0.3s ease;
    /* Smooth transition for hover effect */
}

.upSellHeader {
    background-color: rgb(33, 150, 243);
    font-weight: 600;
    padding: 2px 10px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.upSellHeader p {
    margin-bottom: 0;
}

.upSellCardParent {
    margin-bottom: 4px;
    padding: 5px 4px;
}

.upSellCardParent::-webkit-scrollbar {
    height: 15px !important;
}

.upSellCardParent::-webkit-scrollbar-thumb {
    background-color: #2195f3c7;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Round the scrollbar thumb */
}

.upsellIcon {
    height: 12px;
    width: 12px;
}

.outerSpan>span {
    cursor: default;
    font-size: .9rem !important;
}

#screen-farebooking {
    margin-bottom: 100px;
}

.bb-1 {
    border-bottom: solid 1px rgba(0, 0, 0, .125);
}

.bb-1-secondary {
    border-bottom: solid 1px #fff;
}

.bb-dotted-1 {
    border-bottom: dotted 1px rgba(0, 0, 0, .125);
}


.bl-noradius {
    border-radius: 0 .25rem .25rem 0;
}

.bg-default {
    background-color: var(--theme-bg-color-1, #ffffff) !important;
}

.bg-primary {
    background-color: var(--theme-bg-color-2, #2196f3) !important;
}

.bg-secondary {
    background-color: var(--theme-bg-color-6, #e3f2fd) !important;
}

.bg-dark {
    background-color: var(--base-bg-color-4, #424242) !important;
    color: var(--base-font-color-3, #ffffff) !important;
}

.bg-contrast {
    background-color: var(--theme-bg-color-7, #ffc107);
}

.bg-mediumContrast {
    background-color: rgba(255, 193, 7, 0.3);
    background-color: var(--theme-bg-color-9);
}

.bg-lightContrast {
    background-color: var(--theme-bg-color-8, rgba(255, 193, 7, 0.1));
}


/* Custom Waitscreen             */
/* ***************************** */
#customWaitscreen,
#waitScreenModal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
}

/* When the body has the loading class (e.g. while AJAX is loading), we turn the scrollbar off with overflow:hidden and our modal element will be visible */
body.loading #customWaitscreen,
body.loading #waitScreenModal {
    display: block;
    overflow: hidden;
}


/* General Waitscreen/-icon      */
/* ***************************** */
#generalWaitscreen {
    position: fixed;
    z-index: 9999;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: url(images/waiticon.gif) center no-repeat rgba(255, 255, 255, 0.8);
    display: none;
}


/* Infinite Loader               */
/* ***************************** */
/*
#infiniteContentPreloader {
    background-color: var(--base-bg-color-4, #424242);
    color: var(--base-font-color-3, #ffffff);
    padding: .25rem;
    text-align: center;
    border-radius: 3px;
    display: none;
}
*/



/* Login                         */
/* ***************************** */
#screen-login {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -225px;
    width: 100%;
    z-index: 5;
}

#center-screen-login {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    height: 450px;
}


#card-login {
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.3);
}

.login-info {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    min-height: 25px;
    line-height: 1.5rem;
    background-color: #5a7393;
    color: #fff;
    z-index: 1000;
}



/* Form                          */
/* ***************************** */
/*.input-group-addon {
    background-color: #f6f6f6;
}
*/
.input-group-text {
    background-color: var(--base-bg-color-1, #ffffff);
    border-right: none;
    /* padding: .5rem .4rem !important; */
}

.form-control {
    font-size: 1rem;
    /* font-weight: 300; */
    transition: none;
}

/* Standard */
.form-control::-webkit-input-placeholder {
    color: var(--base-font-color-2, #cdcdcd);
    font-weight: 300;
    text-transform: lowercase;
}

.form-control:-ms-input-placeholder {
    color: var(--base-font-color-2, #cdcdcd);
    font-weight: 300;
    text-transform: lowercase;
}

.form-control::placeholder {
    color: var(--base-font-color-2, #cdcdcd);
    font-weight: 300;
    text-transform: lowercase;
}

/* Focus */
.form-control:focus::-webkit-input-placeholder {
    color: transparent;
}

.form-control:focus:-ms-input-placeholder {
    color: transparent;
}

.form-control:focus::placeholder {
    color: transparent;
}



input[type="text"]:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='date']:focus,
select:focus,
textarea:focus,
button:focus,
button:active,
a.btn:not(.btn-cancel):focus,
a.btn:not(.btn-cancel):active {
    /* border: solid 1px var(--theme-bg-color-2, #2196f3) ; */
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px rgba(125, 125, 125, 0.4) !important;
    outline: 0 none;
}

a.btn.btn-cancel:focus,
a.btn.btn-cancel:active {
    border: solid 1px var(--base-bg-color-0, transparent) !important;
    box-shadow: none !important;
    outline: 0 none;
}

select.form-control:not([size]):not([multiple]) {
    height: auto;
}

/* Sales Preferences             */
/* ***************************** */
.fare__single_fare_infobox .salespref {
    background-color: var(--theme-bg-color-1, #ffffff);
    border: solid 1px var(--theme-bg-color-11, #242424);
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
}




/* **************************
****************************
Modules
****************************
************************** */

/* Header Modules                */
/* ***************************** */
.header-primary {
    background-color: var(--theme-bg-color-11, #242424);
    padding-top: 18px;
    padding-bottom: 18px;
    height: 95px;
}

.header-primary nav .navbar-brand {
    margin-right: 0;
}

.header-primary nav .navbar-brand>img {
    max-height: 70px;
}


/* Footer Modules                */
/* ***************************** */
.mainfooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    line-height: 1.5rem;
    background-color: var(--base-bg-color-1, #ffffff);
    color: var(--base-font-color-1, #242424);
    z-index: 999;
    border-top: solid 0.5rem var(--theme-bg-color-2, #2196f3);
}



/* Nav Modules                   */
/* ***************************** */
.navbar-nav .nav-link {
    font-size: .9rem;
}

.pagingbuttons>.btn {
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #000;
}

/* .nav {
    display: block;
} */

/* .modal-body .nav {
    display: flex;
}

.modal-body .nav-tabs {
    border-bottom: none;
}

.modal-body .nav-item {
    margin-right: .25rem;
}

.modal-body .nav-link {
    background-color: var(--theme-bg-color-4, #bbdefb);
    color: var(--theme-font-color-2, #ffffff);
}

    .modal-body .nav-link.active {
        background-color: var(--theme-bg-color-2, #2196f3);
        color: var(--theme-font-color-2, #ffffff);
    } */

.nav>ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav>ul li {
    float: left;
}


.navbar-dark {
    background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, .1) 15%, transparent 20%) 8px 9px;
    /* background-color: var(--theme-bg-color-11, #242424); */
    background-size: 16px 16px;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .5);
}

/* Mainnav Item */
.navbar-dark .navbar-nav .nav-link {
    background-color: #424242;
    padding: 0.5rem 1rem;
}

/* Mainnav Item ACTIVE */
.navbar-dark .navbar-nav .active>.nav-link {
    background-color: var(--theme-bg-color-2, #2196f3);
    color: #fff;
}

/* Mainnav Item LOGOUT */
.navbar-dark .navbar-nav .logout>.nav-link {
    background-color: #424242;
    color: #fff;
}

/* Mainnav Item DROPDOWN SELECTOR ACTIVE */
.navbar-dark .navbar-nav .show>.nav-link {
    background-color: var(--theme-bg-color-3, #64b5f6);
    color: var(--theme-font-color-2, #ffffff);
}

/* Mainnav Item DROPDOWN Item */
.dropdown-item {
    padding: .2rem 1rem;
    font-size: .95rem;
}

.dropdown-menu-dark,
.dropdown-menu-dark .dropdown-item {
    background-color: #424242;
    color: rgba(255, 255, 255, .5);
}

/* Mainnav Item DROPDOWN Item :HOVER */
.dropdown-menu-dark .dropdown-item:hover {
    color: rgba(255, 255, 255, .75);
}




/* Button Modules                */
/* ***************************** */
button:focus,
input[type="button"]:focus {
    outline: 0;
}

.btn-default {
    background-color: var(--base-bg-color-1, #ffffff);
    border-color: var(--base-bg-color-1, #ffffff);
    color: var(--base-font-color-1, #242424);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    background-color: var(--base-bg-color-2, #f8f9fa) !important;
    border-color: var(--base-bg-color-2, #f8f9fa);
    color: var(--base-font-color-1, #242424) !important;
}

.btn-cancel {
    background-color: var(--base-button-color-2, #e6e6e6);
    color: var(--base-font-color-1, #242424);
}

.btn-cancel:hover {
    background-color: var(--base-button-color-3, #d2d2d2);
    opacity: 0.8;
}

.btn-white {
    background-color: var(--base-bg-color-1, #ffffff);
    color: var(--base-font-color-1, #242424);
}

.btn-primary {
    background-color: var(--theme-button-color-1, #2196f3);
    border-color: var(--theme-button-color-1, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}

.btn-primary:not(.disabled):hover {
    background-color: var(--theme-button-color-2, #64b5f6);
    border-color: var(--theme-button-color-2, #64b5f6);
    text-decoration: none;
}

.btn-primary:active {
    background-color: var(--theme-button-color-1, #2196f3);
    border-color: var(--theme-button-color-1, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}


.btn-secondary {
    background-color: var(--theme-button-color-4, #90caf9);
    border-color: var(--theme-button-color-4, #90caf9);
    color: var(--theme-font-color-2, #ffffff);
}

.btn-secondary:not(.disabled):hover {
    background-color: var(--theme-button-color-5, #90caf9);
    border-color: var(--theme-button-color-5, #90caf9);
    color: var(--theme-font-color-2, #fff);
    opacity: 0.8;
}

.btn-secondary:active {
    background-color: var(--theme-button-color-4, #90caf9);
    border-color: var(--theme-button-color-4, #90caf9);
    color: var(--theme-font-color-2, #ffffff);
}


.btn-outline {
    background-color: var(--base-bg-color-1, #ffffff);
    border-color: var(--base-bg-color-5, #bbbbbb);
    color: var(--base-font-color-1, #242424);
}

.btn-outline:not(.disabled):hover,
.btn-outline:not(.disabled):focus,
.btn-outline:not(.disabled):active,
.btn-outline:not(.disabled):active:focus {
    border-color: #242424;
    outline: none !important;
}


.btn-outline-noborder {
    background-color: transparent;
    background-image: none;
    border: solid 2px var(--base-bg-color-1, #ffffff);
    color: var(--base-font-color-4, #bbbbbb);
    padding: 0 .5rem;
    box-shadow: none;
}

.btn-outline-noborder:not(.disabled):hover {
    background-color: var(--base-bg-color-2, #f8f9fa);
    color: var(--base-font-color-1, #242424);
}

.btn-outline-noborder:focus,
.btn-outline-noborder:active,
.btn-outline-noborder:visited {
    border: solid 2px var(--base-bg-color-1, #fff);
    box-shadow: none;
}

.btn.toggleCalcConnection:disabled,
.btn.toggleCalcConnection:disabled:hover {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: #333 !important;
}


/* .btn-group .btn {    
    border: solid 1px rgba(0, 0, 0, .15);
    color: var(--base-font-color-1, #242424);
    padding: .5rem;
    font-weight: 300;
}

.btn-group .btn.active {
    background-color: var(--theme-button-color-1, #2196f3);
    border-color: var(--theme-button-color-1, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}



.btn-group-sm .btn {
    padding: .35rem .5rem;
} */


.btn.disabled,
.btn:disabled {
    opacity: .45;
    background-color: var(--base-button-color-1, #868e96);
    border-color: var(--base-button-color-1, #868e96);
    cursor: not-allowed;
}


.btn-addSegment,
.btn-removeSegment {
    background-color: transparent !important;
    padding: 0;
}


/* Card Modules                  */
/* ***************************** */

.card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 0;
    padding: .15rem;
}

.card-header {
    padding: .5rem;
}

.card-header-primary {
    background-color: var(--base-bg-color-1, #ffffff);
    color: var(--base-font-color-1, #242424);
    text-transform: uppercase;
    font-weight: 400;
}

.card-header-primary h6 {
    margin-bottom: 0;
}


.card-header-secondary {
    background-color: rgba(0, 0, 0, .03);
    color: var(--base-font-color-1, #242424);
    text-transform: uppercase;
    font-weight: 400;
}

.card-body {
    padding: .5rem;
}

.card-body-primary {
    background-color: var(--theme-bg-color-4, #bbdefb);
    color: var(--theme-font-color-1, #242424);
}

/* .card .card .card-header-primary {
        background-color: rgba(190,190,190,0.2);
        padding: 0.25rem 0.5rem;
        text-transform: none;
        border-bottom: none;
    } */

.card-body-secondary {
    background-color: var(--theme-bg-color-6, #e3f2fd);
    color: var(--theme-font-color-1, #242424);
}


.card-header:first-child {
    border-radius: 0;
}



/* Switch Modules                */
/* ***************************** */
.switch {
    font-size: 1rem;
    position: relative;
}

.switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
}

.switch input+label {
    position: relative;
    min-width: calc(calc(2.375rem * .8) * 2);
    border-radius: calc(2.375rem * .8);
    height: calc(2.375rem * .8);
    line-height: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
}



.switch input+label::before,
.switch input+label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(calc(2.375rem * .8) * 2);
    bottom: 0;
    display: block;
}

.switch input+label::before {
    border: solid 0.2em transparent;
    right: 0;
    transition: 0.1s all;
    width: 6em;
    height: 1.6em;
    border-radius: 1.5em;
    box-shadow: inset 0 0 1.5em rgba(100, 100, 100, 0.5);
    background: var(--inner, linear-gradient(#696969, #c0c0c0)) padding-box, linear-gradient(#c0c0c0, #f5f5f5) border-box;
}

.switch input+label::after {
    top: -2px;
    left: 0;
    padding: 0.2em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    box-shadow: 0 0.3em 0.6em rgba(100, 100, 100, 0.2);
    background: linear-gradient(#c0c0c0, #f5f5f5) content-box, linear-gradient(#f5f5f5, #c0c0c0) border-box;
    color: grey;
    transition: 0.1s all;
}

.switch input:checked+label {
    --inner: linear-gradient(var(--theme-bg-color-2, #2196f3), var(--theme-bg-color-3, #64b5f6));
}

.switch input:checked+label::before {
    background-color: #08d;
}

.switch input:checked+label::after {
    margin-left: calc(2.375rem * .8);
}

.switch input:focus+label::before {
    outline: 1;
    border: 1px solid white;
}

.switch input:disabled+label {
    color: var(--theme-font-color-4, #868e96);
    --inner: linear-gradient(#fff, #fff);
    cursor: not-allowed;
}

.switch input:disabled+label::before {
    background-color: #e9ecef;
}

/* tableRow -color  */
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #00000040 !important;
}

/* small Switch, as used in Bookingmanager */
.switch.switch-sm {
    font-size: .8rem;
}

.switch.switch-sm input+label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(2rem * .8);
    line-height: calc(1.5rem * .8);
}

.switch.switch-sm input+label::before {
    width: calc(calc(2.1rem * .8) * 2);
}

.switch.switch-sm input+label::after {
    width: calc(calc(2.1rem * .8) - calc(2px * 2));
    height: calc(calc(2.1rem * .8) - calc(2px * 2));
}

.switch.switch-sm input:checked+label::after {
    margin-left: calc(2.5rem * .8);
}


/* large Switch, as used on Query page */
.switch.switch-lg {
    font-size: .95rem;
}

.switch.switch-lg input+label::after {
    top: 1px;
}

.switch.switch-lg input+label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(2rem * .8);
    line-height: calc(2rem * .8);
}

.switch.switch-lg input+label::before {
    width: calc(calc(2.5rem * .8) * 2);
}

.switch.switch-lg input+label::after {
    width: calc(calc(2.1rem * .8) - calc(2px * 2));
    height: calc(calc(2.1rem * .8) - calc(2px * 2));
}

.switch.switch-lg input:checked+label::after {
    margin-left: calc(3rem * .8);
}


.fairDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    height: 100%
}

.mdColm {
    flex: 0 0 auto;
    width: 50% !important;
}

/* media query start */
@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .switch,
    .switch-sm,
    .switch input,
    .switch input+label,
    .switch input+label::before,
    .switch input+label::after,
    .switch input+label::before,
    .switch input+label::after,
    .switch input:checked+label,
    .switch input:checked+label::before,
    .switch input:checked+label::after,
    .switch input:focus+label::before,
    .switch input:disabled+label,
    .switch input:disabled+label::before,
    .switch.switch-sm,
    .switch.switch-sm input+label,
    .switch.switch-sm input+label::before,
    .switch.switch-sm input+label::after,
    .switch.switch-sm input:checked+label::after,
    .switch.switch-lg,
    .switch.switch-lg input+label::after,
    .switch.switch-lg input+label,
    .switch.switch-lg input+label::before,
    .switch.switch-lg input+label::after,
    .switch.switch-lg input:checked+label::after {
        animation: none;
        animation-delay: 0;
        animation-direction: normal;
        animation-duration: 0;
        animation-fill-mode: none;
        animation-iteration-count: 1;
        animation-name: none;
        animation-play-state: running;
        animation-timing-function: ease;
        backface-visibility: visible;
        background: 0;
        background-attachment: scroll;
        background-clip: border-box;
        background-color: transparent;
        background-image: none;
        background-origin: padding-box;
        background-position: 0 0;
        background-position-x: 0;
        background-position-y: 0;
        background-repeat: repeat;
        background-size: auto auto;
        border: 0;
        border-style: none;
        border-width: medium;
        border-color: inherit;
        border-bottom: 0;
        border-bottom-color: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-collapse: separate;
        border-image: none;
        border-left: 0;
        border-left-color: inherit;
        border-left-style: none;
        border-left-width: medium;
        border-radius: 0;
        border-right: 0;
        border-right-color: inherit;
        border-right-style: none;
        border-right-width: medium;
        border-spacing: 0;
        border-top: 0;
        border-top-color: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-style: none;
        border-top-width: medium;
        bottom: auto;
        box-shadow: none;
        box-sizing: content-box;
        caption-side: top;
        clear: none;
        clip: auto;
        color: inherit;
        columns: auto;
        column-count: auto;
        column-fill: balance;
        column-gap: normal;
        column-rule: medium none currentColor;
        column-rule-color: currentColor;
        column-rule-style: none;
        column-rule-width: none;
        column-span: 1;
        column-width: auto;
        content: normal;
        counter-increment: none;
        counter-reset: none;
        cursor: auto;
        direction: ltr;
        display: inline;
        empty-cells: show;
        float: none;
        font: normal;
        font-family: inherit;
        font-size: initial;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        height: auto;
        hyphens: none;
        left: auto;
        letter-spacing: normal;
        line-height: initial;
        list-style: none;
        list-style-image: none;
        list-style-position: outside;
        list-style-type: disc;
        margin: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        max-height: none;
        max-width: none;
        min-height: 0;
        min-width: 0;
        opacity: 1;
        orphans: 0;
        outline: 0;
        outline-color: invert;
        outline-style: none;
        outline-width: medium;
        overflow: visible;
        overflow-x: visible;
        overflow-y: visible;
        padding: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        page-break-after: auto;
        page-break-before: auto;
        page-break-inside: auto;
        perspective: none;
        perspective-origin: 50% 50%;
        position: static;
        right: auto;
        tab-size: 8;
        table-layout: auto;
        text-align: inherit;
        text-align-last: auto;
        text-decoration: none;
        text-decoration-color: inherit;
        text-decoration-line: none;
        text-decoration-style: solid;
        text-indent: 0;
        text-shadow: none;
        text-transform: none;
        top: auto;
        transform: none;
        transform-style: flat;
        transition: none;
        transition-delay: 0s;
        transition-duration: 0s;
        transition-property: none;
        transition-timing-function: ease;
        unicode-bidi: normal;
        vertical-align: baseline;
        visibility: visible;
        white-space: normal;
        widows: 0;
        width: auto;
        word-spacing: normal;
        z-index: auto;
    }
}



/* Table Modules                 */
/* ***************************** */

.tableFixHead {
    overflow-y: auto;
    height: 500px;
}

.tableFixHead table {
    border-collapse: collapse;
    width: 100%;
}

.tableFixHead th {
    position: sticky;
    top: 0;
    z-index: 50;

}

.table-responsive-xl {
    padding-bottom: 2px;
}

/* .table thead th {
    font-weight: 400;
} */

.table .thead-dark th {
    background-color: var(--base-bg-color-4, #424242);
    border-color: var(--base-bg-color-5, #bbbbbb);
    color: var(--base-font-color-3, #ffffff);

}

.table .thead-light th {
    background-color: var(--theme-bg-color-13, rgba(82, 162, 222, .1));
    border-color: var(--theme-bg-color-12, #dee2e6);
    color: var(--theme-font-color-1, #242424);
}





/* Modal Modules                 */
/* ***************************** */

.modal-title {
    text-transform: uppercase;
    color: #242424;
}

.modal-body {
    font-size: .85rem;
}

.modal__flight_details .modal-body {
    font-size: 0.7rem;
}

.modal-footer .btn {
    font-size: .85rem;
}



/* Modal Flight Details       */
/* -------------------------- */
.modal__flight_details .flight__summary_box {
    background-color: #fff;
}

.modal__flight_details .flight__summary_box .col {
    padding: 3px 0;
}

.modal__flight_details .modal__segment-header {
    background-color: var(--theme-bg-color-10, #cddae4);
    margin: -10px -15px 0 -15px;
    padding: 7px 15px;
}


/* Modal Fare Rules           */
/* -------------------------- */
#modal__flight_rules .modal-xl {
    max-width: 900px;
}

#modal__fare_rules__routing_content table {
    font-size: 0.9rem;
}

#modal__fare_rules__routing_content table td {
    vertical-align: top;
    border-top: none;
    border-bottom: solid 1px #ddd;
}

#modal__fare_rules__routing_content table tr td:first-child {
    width: 20%;
    padding-left: 1.25rem;
}

#modal__fare_rules__routing_content table tr:last-child td {
    border-bottom: none;
}





/* *************************
***************************
All other page elements 
***************************
************************* */

.highlight-row td {
    background-color: var(--theme-bg-color-10, #f1f7fb) !important;
    color: var(--theme-font-color-1, #242424) !important;
}




/* Query page                */
/* ------------------------- */
.deleteSegment {
    cursor: pointer;
    color: var(--theme-font-color-1, #242424);
}

#inputPassengers {
    border: solid 1px #ced4da;
}

#selectPassengers {
    background-color: var(--base-bg-color-1, #ffffff);
}


.amount__Digit,
.amount__Digit_Counter {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 2px;
    border: solid 1px #bbb;
}

.amount__Digit {
    background-color: var(--theme-bg-color-6, #e3f2fd);
    color: var(--theme-font-color-1, #242424);
}

.amount__Digit:hover {
    background-color: var(--theme-bg-color-5, #90caf9);
    color: var(--theme-font-color-2, #ffffff);
}

.amount__Digit_Counter {
    background-color: var(--base-bg-color-1, #ffffff);
}

.amount__Digit>input[type=button] {
    border: solid 1px transparent;
    background: transparent;
    background-image: none;
    box-shadow: none;
    line-height: 18px;
}

.paymentInputBox input[type="number"] {
    -moz-appearance: textfield !important;
    /* Firefox */
}

/* Hide the arrows in Webkit browsers (Safari, Chrome) */
.paymentInputBox input[type="number"]::-webkit-inner-spin-button,
.paymentInputBox input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    /* Removes the default margin */
}

.errorPaymentTag {
    font-size: 14px;
    color: red;
    padding-left: 14px;
}

.paxTyp {
    line-height: 30px;
}

.paxSelector__dialog .ui-dialog-titlebar {
    padding: 5px 15px 5px 5px;
}

.paxSelector__dialog .form-control {
    height: calc(1.7rem + 2px);
    max-width: 150px;
}


#distribChannels {
    height: 120px;
    width: 100%;
}

#distribChannels .custom-control {
    height: 1.5rem;
}

#distribChannels ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
}



#distribChannels ul li .custom-control {
    margin: 0;
    padding: 0;
}

#distribChannels ul li .custom-control label {
    padding-left: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}




/* FareQuery Options         */
/* ------------------------- */
#farequery__options .form-group {
    margin-bottom: 0;
}

#farequery__options h4 {
    margin-bottom: 1.5rem;
}

#farequery_history {
    padding-left: 1rem;
    color: #777;
}

#farequery_history a {
    color: #2196f3
}

#farequery_history tbody {
    height: 110px;
    display: inline-block;
    width: 100%;
    overflow: auto;
}

#farequery_history td {
    padding: .2rem 1rem;
    font-size: .9rem;
}

#farequery_history tr td:first-child {
    padding: .2rem .5rem .2rem 0;
}






/* Fare display              */
/* ------------------------- */
#container__ModifySearch {
    background-color: transparent;
}

#container__ModifySearch .input-group-prepend .input-group-text {
    background-color: var(--base-bg-color-1, #ffffff);
    padding: 0rem .5rem;
    border-right: none;
}

#container__ModifySearch .btn-group-sm .btn {
    padding: .45rem .5rem;
}

#container__ModifySearch .input-group-sm>.form-control {
    padding: 0px 4px;
}

#container__ModifySearch #inputPassengers {
    padding: 0px 5px;
}


/* ----------------------------- */

.filter-section {
    color: var(--base-font-color-1, #242424);
    font-weight: 500;
    font-size: 1rem;
    border-left: solid 10px #2196f3;
    border-bottom: solid 1px #2196f3;
    text-transform: uppercase;
    margin: 0 0 1rem -2rem;
    padding-left: 1.3rem;
}

/* ----------------------------- */

#fare__fastfilter_container .row {
    margin: 0;
}

#fare__fastfilter_container .fare__fastfilter_box {
    padding: 0 .5rem 0.25rem;
    border: solid 1px #ccc;
    background-color: var(--theme-bg-color-6, #e3f2fd);
    color: var(--theme-font-color-1, #242424);
    position: relative;
    cursor: pointer;
    opacity: 1;
}

#fare__fastfilter_container .fare__fastfilter_box:first-child {
    border-right: none;
}

#fare__fastfilter_container .fare__fastfilter_box:last-child {
    border-left: none;
}

#fare__fastfilter_container .fare__fastfilter_box.active {
    background-color: var(--theme-bg-color-2, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}

.fare__fastfilter_box_cheapest:after,
.fare__fastfilter_box_fastest:after,
.fare__fastfilter_box_best:after {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    font-size: 68px;
    color: #bbdefb;
    opacity: 0.5;
    top: -15px;
    right: 5px;
    z-index: 1;
}

.fare__fastfilter_header,
.fare__fastfilter_price,
.fare__fastfilter_duration {
    z-index: 2;
}

.fare__fastfilter_header {
    font-size: 1.1rem;
    line-height: 1.7;
    font-weight: 300;
    text-transform: uppercase;
}

#fare__fastfilter_container .fare__fastfilter_box.active .fare__fastfilter_header {
    font-weight: 400;
}

.fare__fastfilter_price {
    font-size: 0.85rem;
    font-weight: 300;
}

.fare__fastfilter_duration {
    font-size: 0.85rem;
    font-weight: 300;
}


/*  ****  */

.fare__selector_container {
    /* background-color: var(--theme-bg-color-4, #bbdefb); */
    margin-bottom: 2rem;
}

.fare__selector_container .row {
    margin: 0;
}

.fare__selector_container .row.singlefare {
    margin-bottom: 1rem;
}

.fare__single_fare_infobox {
    font-size: .9rem;
    padding: 0.35rem 0 0 0;
}

.fare__single_fare_infobox.boxShadow-light {
    padding: .35rem 0 0.75rem 0;
}

.fare__single_fare_infobox .infobox_item {
    color: #242424;
}

.fare__single_fare_infobox .infobox_value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #a0a0a0;
}



.fare__single_fare {
    background-color: var(--base-bg-color-1, #ffffff);
    /* padding-bottom: 3px; */
}

.starIcon {
    color: #ff7b00 !important;
}

.fare__single_fare .toggleFareDetails,
.fare__single_fare .toggleAllPaxDetails {
    cursor: pointer;
}

.fare__single_fare .toggleEye {
    width: 30px !important;
    cursor: pointer;
    padding: 10px 0;
    text-align: center;
    background-color: var(--base-bg-color-5, #bbbbbb);
    color: #000;
}

.fare__carrier_logo {
    min-width: 140px;
}

.fare__carrier_text {
    text-transform: uppercase;
    color: var(--base-font-color-1, #242424);
    font-weight: 400;
}

.fareRow {
    cursor: pointer;
}

.fare__carrier_class {
    padding-top: .25rem;
    cursor: pointer;
}

.fare__carrier_class span.class_name {

    text-transform: uppercase;
    font-size: .75rem;
    border-radius: .125rem;
    padding: 0 .25rem;
    color: var(--base-font-color-3, #ffffff);
}

.fare__carrier_class span.class_name_y {
    background-color: var(--base-bg-color-6, #888888);
}

.fare__carrier_class span.class_name_p {
    background-color: var(--base-bg-color-7, #b8860b);
}

.fare__carrier_class span.class_name_c {
    background-color: var(--base-bg-color-8, #038103);
}

.fare__carrier_class span.class_name_f {
    background-color: var(--base-bg-color-9, #8b253b);
}


.fare__carrier_price {
    text-align: left;
    color: inherit;
    background-color: var(--base-bg-color-10, rgba(215, 215, 215, .25));
    padding: .25rem 0 .5rem .5rem;
}

.fare__carrier_price div.price {
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 400;
}

.fare__carrier_price div.tax {
    font-size: .75rem;
    line-height: 1;
    font-weight: normal;
    padding-top: 0.25rem;
    color: #777;
}



.fare__departure_airport,
.fare__arrival_airport {
    font-size: .9rem;
    font-weight: bold;
}




.segment__moreFlightTimes table,
.fare__FlightDetails table {
    font-size: 0.8rem;
    margin-bottom: 0;
    background-color: var(--base-bg-color-2, #f8f9fa);
}




/* FARE CALCULATION */
.fare__calculation table {
    font-size: 0.85rem;
    margin-bottom: 0;
}

.fare__calculation table th {
    border-bottom: solid 2px #ccc;
    font-weight: 500;
}



/* SEGMENTS for _SELECTEDFlight*/
.segmentselected {
    display: block;
}

.modalEffectBg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background-color: gray;
    opacity: 0.7;
    overflow-y: auto;
    outline: 0;
}

.zIndexBg {
    z-index: 1000 !important;
}

/* SEGMENTS */
.segment,
.fare__footer,
.fare__single_fare_infobox .sliderContent {
    display: none;
}

.segment__departure_airport {
    font-size: 1.1rem;
    line-height: 2.4rem;
    color: var(--base-font-color-1, #242424);
    font-weight: 500;
    padding: 0;
    width: 13%;
    min-width: 40px;
}

.segment__arrival_airport {
    font-size: 1.1rem;
    line-height: 2.4rem;
    color: var(--base-font-color-1, #242424);
    font-weight: 500;
    padding-left: 2%;
    width: 13%;
    min-width: 40px;
}

.segment__departure_time {
    font-size: .9rem;

    color: var(--base-font-color-1, #242424);
    font-weight: 300;
    padding: 0;
    width: 12%;
    text-align: center;
}

.segment__arrival_time {
    font-size: .9rem;

    color: var(--base-font-color-1, #242424);
    font-weight: 300;
    padding: 0;
    width: 12%;
    min-width: 80px;
    text-align: center;
}

.segment__arrival_time .nextday,
.segment__moreFlightTimes .nextday {
    /* display: inline-block; */
    width: auto;
    min-width: 28px;
    color: var(--base-font-color-6, #ff0000);
    font-weight: 500;
    white-space: nowrap;
    /* text-align: center; */

}

.segment__moreFlightTimes .nextday {
    margin-left: 3px;
}

.segment__moreFlightTimes .nextday sup {
    font-size: .7rem;
}

.segment__stops {
    width: 25%;
}

.segment__stops_duration {
    text-align: center;
}

.segment__stops_traveltime {
    font-size: .9rem;
    font-weight: 400;
    line-height: 1;
    color: var(--base-font-color-7, #2196f3);
    margin-bottom: -2px;
    text-align: center;
}

.segment__stops_travelstops {
    text-align: center;
    line-height: 1;
    margin-top: 2px;
    border-top: solid 2px #ccc;
    padding-top: 2px;
}

.segment__stops_totalstops {
    font-size: 0.80rem;
    font-weight: 300;
    line-height: 1.2;
    white-space: nowrap;
}

.segment__stops_direct {
    color: var(--base-font-color-8, #0ec051);
}

.segment__stops_1 {
    color: var(--base-font-color-9, #eabd18);
}

.segment__stops_2plus {
    color: var(--base-font-color-10, #f63636);
}


/* SEGMENT FLIGHT TIMES */
.segment__moreFlightTimes table,
.segment__selectedFlightTimes table {
    margin-bottom: 0;
    background-color: var(--base-bg-color-2, #f8f9fa);
}

.segment__selectedFlightTimes table {
    font-size: 0.9rem;
}

.segment__moreFlightTimes table .nextday sup {
    font-size: 0.7rem;
}

.segment__selectedFlightTimes table nextday sup {
    font-size: 0.8rem;
}


.segment__moreFlightTimes table th,
.segment__moreFlightTimes table td,
.segment__selectedFlightTimes table th,
.segment__selectedFlightTimes table td {
    width: auto;
    padding: .2rem .1rem;
    white-space: nowrap;
    font-size: .75rem;
}

/* .segment__moreFlightTimes table tr th:first-child,
.segment__moreFlightTimes table tr td:first-child {
    width: 1%;
    padding: 0 0 0 2rem;
}


.segment__moreFlightTimes table tr th:first-child,
.segment__moreFlightTimes table tr td:first-child {
    vertical-align: middle;
} */


.segment__moreFlightTimes table th,
.segment__selectedFlightTimes table th,
.fare__FlightDetails table th {
    border-bottom: solid 2px #ccc;
    font-weight: 500;
}

.segment__moreFlightTimes table tr th:first-child .custom-control,
.segment__moreFlightTimes table tr td:first-child .custom-control {
    padding: 0;
    margin: 0;
}

.segment__moreFlightTimes table tr.calcConnection {
    display: none;
}

.segment__moreFlightTimes table tr.calcConnection th,
.segment__moreFlightTimes table tr.calcConnection td {
    background-color: #eee;
    font-size: 0.75rem;
}

.segment__moreFlightTimes table tr.calcConnection th:first-child,
.segment__moreFlightTimes table tr.calcConnection td:first-child {
    padding-left: 0.25rem !important;
}

.segment__moreFlightTimes table tbody tr.calcConnection:last-child {
    border-bottom: solid 2px #999;
}


.connectionHeaderActive {
    background-color: var(--theme-bg-color-15, #fbf5e9);
    color: var(--theme-font-color-1, #242424);
    transition: background-color 0.5s ease-out 0s;
}


/* Stops style*/
.segment__stops_travelstops::before {
    left: -5px;
    /* Adjust the horizontal spacing from the border */
}

.segment__stops_travelstops::after {
    right: -5px;
    /* Adjust the horizontal spacing from the border */
}

.segment__stops_totalstops {
    font-size: 0.80rem;
    font-weight: 300;
    line-height: 1.2;
    white-space: nowrap;
}

.segment__stops_direct {
    color: var(--base-font-color-8, #0ec051);
    margin-top: 4px;
}

.segment__stops_1plus {
    color: var(--base-font-color-9, #eabd18);
    margin-top: 4px;
}

.segment__stops_2plus {
    color: var(--base-font-color-10, #f63636);
    margin-top: 4px;
}


.segment__moreFlightTimes table,
.segment__selectedFlightTimes table {
    margin-bottom: 0;
    background-color: var(--base-bg-color-2, #f8f9fa);
}

.segment__selectedFlightTimes table {
    font-size: 0.9rem;
}

.segment__moreFlightTimes table .nextday sup {
    font-size: 0.7rem;
}

.segment__selectedFlightTimes table nextday sup {
    font-size: 0.8rem;
}


.segment__moreFlightTimes table th,
.segment__moreFlightTimes table td,
.segment__selectedFlightTimes table th,
.segment__selectedFlightTimes table td {
    width: auto;
    padding: .2rem .1rem;
    white-space: nowrap;
    font-size: .75rem;
}


.segment__stops_traveltime {
    font-size: .9rem;
    font-weight: 400;
    line-height: 1;
    color: var(--base-font-color-7, #2196f3);
    margin-bottom: 6px;
    text-align: center;
}

.segment__stops_travelstops {
    text-align: center !important;
    line-height: 1 !important;
    margin-top: 2px !important;
    border-top: solid 2px #ccc !important;
    padding-top: 2px !important;
    position: relative !important;
}

.segment__stops_travelstops::before,
.segment__stops_travelstops::after {
    content: " ";
    /* Use an empty space or any non-empty value */
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    width: 10px;
    /* Adjust the width of the balls as needed */
    height: 10px;
    /* Adjust the height of the balls as needed */
    background-color: #ccc;
    /* Adjust the color of the balls as needed */
    /* border-radius: 50%; */
    /* Create a round shape */
}

.segment__stops_1 {
    background-color: var(--base-font-color-9, #eabd18);
}

.segment__stops_2 {
    background-color: var(--base-font-color-10, #f63636);
}

.airportNameContainerDiv {
    position: relative !important;
    bottom: 34.5px !important;
    display: flex;
}

.point {
    display: inline-block;
    /* width: 12px;
    height: 12px; */
    /* background-color: black; */
    /* margin: 0 20px; */
    /* Adjust the spacing between points */
    /* border: 2px solid #ccc; */
    position: relative !important;
    bottom: 12.5px !important;
    font-size: 12px;
    font-weight: 600;
    padding: 3px !important;
    /* border-radius: 10px; */
    height: 8px !important;
    color: white;
    z-index: 1;
}

.fare__FlightDetails table th,
.fare__FlightDetails table td {
    width: 14%;
    white-space: nowrap;
}

#faredisplay__filter .card {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-bottom: 10px !important;
}

#faredisplay__filter .card-body {
    font-size: 0.9rem;
}

#faredisplay__filter label {
    margin-bottom: 0;
}

#faredisplay__filter #filter__FareType label,
#faredisplay__filter #filter__IncludedServices label {
    text-transform: uppercase;
}


#faredisplay__filter #select__Inbound_StopDuration_handle.ui-slider,
#faredisplay__filter #select__Inbound_StopDuration_handle.ui-slider-handle {
    border: solid 1px #c5c5c5;
}


#faredisplay__filter .toggler {
    width: 30px;
    height: 58px;
    right: -30px;
}

#faredisplay__filter .select__departuretime_text,
#faredisplay__filter .select__arrivaltime_text {
    display: block;
    margin-bottom: -20px;
}



.page-item.active .page-link {
    color: var(--theme-font-color-2, #ffffff);
    background-color: var(--theme-bg-color-2, #2196f3);
    border-color: var(--theme-bg-color-2, #2196f3);
}



/* FARE DISPLAY LIGHT  (in OLD AGENT: Fare Display) */
.DTFC_Cloned tbody>tr>td {
    vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    padding-bottom: 10px;
}

#faredisplay__matrix_table_wrapper .dataTables_scrollBody table {
    width: auto !important;
    float: left;
}

#faredisplay__matrix_table_wrapper th,
#faredisplay__matrix_table_wrapper td {
    text-align: right;
    white-space: nowrap;
    cursor: pointer;
}

#faredisplay__matrix_table_wrapper th.empty,
#faredisplay__matrix_table_wrapper th:empty,
#faredisplay__matrix_table_wrapper td:empty {
    cursor: default;
}

#faredisplay__matrix_table_wrapper th:first-child {
    text-align: left;
}

#faredisplay__matrix_table_wrapper thead th {
    font-weight: normal;
}

#faredisplay__matrix_table_wrapper tbody tr td:first-child {
    text-align: left;
    border-right: solid 2px #ccc;
}

#faredisplay__matrix_table_wrapper tbody tr .bg-light {
    border-right: solid 2px #ccc;
}

#faredisplay__matrix_table tbody tr:hover,
#faredisplay__matrix_table tbody td.hover {
    background-color: rgba(30, 138, 228, 0.15) !important;
}

/* -------- */

.faredisplay__grid_table thead th {
    vertical-align: bottom;
}

#faredisplay__grid_table th,
#faredisplay__grid_table td {
    cursor: default;
}

#faredisplay__grid_table td {
    white-space: nowrap;
}

#faredisplay__grid td.cabinclass_y {
    background-color: #fed268;
}

#faredisplay__grid td.cabinclass_p {
    background-color: #6bd177;
}

#faredisplay__grid td.cabinclass_c {
    background-color: #5584ed;
}

#faredisplay__grid td.cabinclass_f {
    background-color: #f17376;
}




/* Fare booking              */
/* ------------------------- */
.pax-details .form-group {
    margin-bottom: .5rem;
}

.pax-details .form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 0.9rem;
}

.pax-details .pax-type i {
    width: 20px;
    margin-right: 5px;
}





/* Ancillary Services        */
/* ------------------------- */

.ancillaries svg {
    color: var(--base-font-color-11, #949494);
    margin-right: 3px;
    cursor: pointer;
    font-size: 0.85rem;
}

.ancillary_accordion .card {
    box-shadow: none;
    padding: 0;
    margin-bottom: .7rem;
}

.ancillary_accordion .card-header {
    padding: .5rem 1rem;
    border-left: solid 10px transparent;
}

.ancillary_accordion .card-body {
    padding: 1rem;
}

.ancillary_accordion .card-body table thead tr:first-child th {
    border-top: none;
}

.ancillary_accordion .card-body table th,
.ancillary_accordion .card-body table td {
    vertical-align: middle;
    font-size: .85rem;
}

.ancillary_accordion .card-body table td:first-child {
    width: 75px;
}


.ancillariy_section__selected {
    font-weight: bold !important;
}

.ancillariy_card__selected {
    border-left: solid 10px #ffc107 !important;
}


#ancillary__Summary {
    background-color: var(--base-bg-color-11, #949494);
    padding: 10px;
}

#ancillary__Summary div {
    padding: 5px 10px;
}

#ancillary__Summary div>div {
    background-color: none;
    padding: 0;
}

#ancillary__Summary .ancillary__orgFare {
    background-color: #eee;
    font-weight: bold;
    padding-left: 20px;
    font-size: .95rem;
    margin-bottom: 1px;
}

#ancillary__Summary .ancillary__selected {
    background-color: var(--base-bg-color-2, #f8f9fa);
    border-left: solid 10px #ffc107;
    margin-bottom: 1px;
}

#ancillary__Summary .ancillary__subtotal {
    background-color: var(--base-bg-color-2, #f8f9fa);
    border-left: solid 10px #2196f3;
    font-weight: bold;
    font-size: .95rem;
    margin-top: 5px;
}

#ancillary__Summary .ancillary__option {
    font-size: .85rem;
}

#ancillary__Summary .ancillary__price {
    font-weight: bold;
    white-space: nowrap;
    text-align: right;
    font-size: .95rem;
}

#ancillary__Summary .ancillary__selected .ancillary__price {
    color: var(--base-font-color-12, #bb0000);
}


#ancillary__Summary .ancillary__orgFare .ancillary__price,
#ancillary__Summary .ancillary__subtotal .ancillary__price {
    font-size: inherit;
    display: inline-block;
    float: right;
}




/* Up-/Downsell              */
/* ------------------------- */

#modal__upsell .modal-footer {
    padding: 1rem 0 0 1rem;
}


.table__upsell {
    background-color: var(--base-bg-color-1, #fff);
}

.table__upsell .upsell_price {
    padding-left: 25px;
}

.table__upsell i {
    color: var(--base-font-color-1, #242424);
}


.table__upsell th,
.table__upsell td {
    text-align: center;
}

.table__upsell tbody td {
    font-size: 0.90rem;
}

.table__upsell tbody tr th,
.table__upsell tbody tr td {
    width: 30%;
}

.table__upsell tbody tr th:first-child,
.table__upsell tbody tr td:first-child {
    width: 50px;
}

.table__upsell tbody tr td:first-child i {
    border: solid 1px #242424;
    border-radius: 50%;
    font-size: 0.85rem;
    padding: 4px 5px 7px 5px;
    width: 24px;
    height: 24px;
    text-align: center;
}

.table__upsell .active_package {
    border-left: solid 2px green !important;
    border-right: solid 2px green !important;
}

.table__upsell thead th.active_package {
    border-top: solid 2px green !important;
}

.table__upsell tbody tr:last-child td.active_package {
    border-bottom: solid 2px green !important;
}


#upsell__Summary {
    background-color: var(--base-bg-color-11, #949494);
    padding: 10px;
}

#upsell__Summary div {
    background-color: var(--base-bg-color-2, #f8f9fa);
    font-size: .85rem;
    display: block;
    padding: 5px 10px;
}

#upsell__Summary div>div {
    background-color: none;
    display: inline-block;
    padding: 0;
}

#upsell__Summary .upsell__orgFare {
    font-weight: bold;
    margin-bottom: 1px;
}

#upsell__Summary .upsell__selected {
    border-left: solid 10px #ffc107;
    margin-bottom: 1px;
}

#upsell__Summary .upsell__subtotal {
    border-left: solid 10px #2196f3;
    font-weight: bold;
    margin-top: 5px;
}


#upsell__Summary .upsell__routing {
    float: left;
}

#upsell__Summary .upsell__price {
    font-weight: bold;
    white-space: nowrap;
    text-align: right;
    float: right;
}

.filterFlexContainer {
    display: flex !important;
    flex-direction: column;
}

/* Booking confirmation      */
/* ------------------------- */

#screen-bookingconfirmation .pax-type {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    padding-top: 1rem;
}


#screen-bookingconfirmation .pax-type {
    font-weight: bold;
}

#screen-bookingconfirmation .pax-details_item {
    margin-bottom: .5rem;
    border-bottom: dotted 1px #ddd;
}




/* Booking Manager           */
/* ------------------------- */
.filterbox {
    background-color: var(--base-bg-color-1, #ffffff);
    border-radius: .25rem;
    padding: 0.5rem;
}


table#bookinglist__allbookings {
    font-size: 0.85rem;
}

table#bookinglist__allbookings td {
    vertical-align: middle;
}

table#bookinglist__allbookings .row {
    padding: .5rem 1rem .75rem 1.5rem;
}


table#bookinglist__allbookings .dropdown-toggle {
    border: solid 1px #efefef;
}

table#bookinglist__allbookings .dropdown-toggle::after {
    margin: 0 .255em;
}


#bookinglist__filter {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-bottom: 20px !important;
}

#bookinglist__filter .card-body {
    font-size: 0.8rem;
}

#bookinglist__filter label {
    margin-bottom: 0;
}

#bookinglist__filter .toggler {
    width: 30px;
    height: 58px;
    right: -30px;
}

#bookinglist__filter .toggler .glyphicon i {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 16px 0 0 3px;
    font-size: 1.5em;
}

#screen-bookingmanager .card-header,
#screen-bookingmanager .card-body {
    padding: .5rem;
}

#screen-bookingmanager .filterbox {
    padding: 0.5rem;
}

#screen-bookingmanager .filterbox label {
    font-size: 0.9rem;
    font-weight: 300;
}

#screen-bookingmanager #bookinglist__allbookings_filter input[type="search"] {
    -webkit-appearance: textfield;
    padding: .25rem .5rem;
    line-height: 1.5;
    font-size: 0.9rem;
    transition: none;
    color: var(--base-font-color-13, #495057);
    background-color: var(--base-bg-color-1, #ffffff);
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .2rem;
}

#modal__seatreservation .modal-dialog {
    width: 950px !important;
    height: auto;
}



/* Booking Details           */
/* ------------------------- */
table.statuslist__singlebooking {
    font-size: 0.85rem;
    background-color: var(--base-bg-color-1, #ffffff);
    margin-right: 1.5rem;
}

table.statuslist__singlebooking:last-child {
    margin-right: 0;
}





/* *************************
***************************
Overwrite jQuery UI Styles 
***************************
************************* */

.ui-widget-overlay {
    background: #242424;
}

.ui-widget.ui-autocomplete {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--base-font-color-1, #242424);
    position: absolute;
    cursor: default;
    z-index: 3000 !important;
}

.ui-widget.ui-autocomplete li,
.ui-widget.ui-autocomplete li a {
    display: block;
    white-space: nowrap;
    text-decoration: none;
}

.ui-autocomplete .ui-state-active {
    background-color: var(--theme-bg-color-2, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}

.ui-widget.ui-widget-overlay {
    background: #000;
    opacity: .40;
    filter: Alpha(Opacity=40);
}

.ui-datepicker .ui-datepicker-header {
    background-color: var(--theme-bg-color-6, #e3f2fd);
    color: var(--theme-font-color-1, #242424);
}

.ui-datepicker td .ui-state-active {
    background-color: var(--theme-bg-color-2, #2196f3);
    color: var(--theme-font-color-2, #ffffff);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #ccc;
}



/* *************************
***************************
Overwrite other Plugin Styles 
***************************
************************* */

.fa,
.fas {
    width: auto;
}



.fa-info-circle {
    cursor: pointer;
}

.fa-child:before {
    padding-left: 2px;
}



/* ----------------------------------------- */

table.dataTable {
    border-collapse: collapse;
}

table.dataTable.table-sm th,
table.dataTable th {
    padding: 2px 5px;
}

table.dataTable.table-sm td,
table.dataTable td {
    padding: 2px 5px;
}

.dataTables_wrapper .dataTables_filter {
    float: left;
    text-align: left;
    margin-bottom: .5rem;
}

.dataTables_wrapper .dataTables_filter input {
    font-weight: 300;
    font-size: 0.85rem;
}

table.dataTable thead,
table.dataTable tfoot {
    font-size: 0.85rem;
}

table.dataTable thead small {
    color: #fff;
    font-size: 0.7rem;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-position: right bottom;
    padding-right: 20px;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
    bottom: 5px;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none;
}

table.dataTable td.dataTables_empty {
    padding: 0.75rem;
}


td.details-control {
    text-align: center;
    vertical-align: top;
    width: 15px;
}

td.details-control:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f055";
    cursor: pointer;
    color: darkgreen;
}

tr.shown td.details-control:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f056";
    color: darkred;
}

.details-table {
    margin: 0;
}

.details-table td col {
    padding: 3px 5px;
}


/* ---------------SIDEBAR-------------------------- */

.bm-overlay {
    top: -1px
}

.bm-menu-wrap {
    /* margin-left:-4%; */
    position: fixed;
    height: 90%;
    top: -1px
}

.bm-menu>.menu-wrapper {
    padding: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    overflow-x: hidden;
    width: 100%;
}

.bm-menu.card-header {
    border-radius: 0;
}

.bm-burger-button {
    position: fixed;
    font-size: 2em;
    top: 50%;
}

.bm-burger-bars {
    background: '#373a47'
}

.bm-cross-button {
    z-index: "9999"
}

.bm-item-list {
    padding: 0px;
}

.flightSearchCardPosition {
    position: relative;
}

.flightSearchBtn {
    position: absolute;
    right: 3px;
    bottom: 31px;
    /* display: flex;
    width: 200px;
    align-items: end;
    position: absolute;
    right: 6px;
    bottom: 18px; */
}

.flightSearchBtnWithOff {
    position: absolute;
    right: 0px;
    bottom: 10px;
}

.flightSearchBtnWithOff button,
.flightSearchBtn button {
    height: 100% !important;
    gap: 8px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.cabinBtnOffDiv {
    height: 85% !important;
    width: 18% !important;
    margin-top: 7px !important;
}

.cabinBtnOnDiv {
    height: 100% !important;
    width: 14% !important;
}

.cabinBtnOn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.removeBtn {
    padding: 10px;
    width: 10%;
}

@media only screen and (max-width: 600px) {
    .removeBtn {
        padding: 0px;
        width: 10%;
    }
}

@media only screen and (min-device-width : 360px) and (max-device-width : 1024px) {
    .removeBtn {
        padding: 0px;
        width: 10%;
    }
}

/* upsale table */
.vertical-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
}

.vertical-table th,
.vertical-table td {
    border: 1px solid #ddd;
    padding: 12px;
}

.vertical-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    width: 150px;
}

.vertical-table td {
    text-align: center;
}

.upsellIcon path {
    fill: #949494;
}

/* upsale end */


/* **************************
****************************
Media Queries
****************************
************************** */

/* Extra small devices (portrait phones, less than 576px): 
   no media query since this is the default in Bootstrap  */
/* ****************************************************** */



/* Small devices (landscape phones, 576px and up)      */
/* *************************************************** */
@media (min-width: 576px) {
    .bm-menu-wrap {
        margin-left: -17px;
    }

    .container {
        max-width: 95%;
    }

    .content-wrapper {
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.3);
        position: relative;
        z-index: 3;
    }

    /* ----------------------------------------- */

    #screen-query,
    #screen-faredisplay,
    #screen-farebooking,
    #screen-bookingconfirmation,
    #screen-bookingmanager {
        margin-top: 2.4rem;
    }

    /* ----------------------------------------- */

    .fare__carrier_class span.class_name {
        font-size: .8rem;
    }

    .fare__departure_airport,
    .fare__arrival_airport {
        font-size: 1.1rem;
    }


    .fare__stops_traveltime {
        font-size: 0.8rem;
    }

    /* ----------------------------------------- */

    .fare__fastfilter_box_cheapest:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f155";
    }

    .fare__fastfilter_box_fastest:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        content: "\f017";
    }

    .fare__fastfilter_box_best:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        content: "\f118";
    }

    /* ----------------------------------------- */

    .input-group .form-select:not(:first-child),
    .input-group .form-control:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}



/* Medium devices (tablets, 768px and up)              */
/* *************************************************** */
@media (min-width: 768px) {
    .container {
        max-width: 95%;
    }

    .content-wrapper {
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.3);
        position: relative;
        z-index: 3;
    }

    .center-content {
        width: 80%;
        height: 50vh;
    }

    /* ------------------------------------------ */

    .card-header {
        padding: .5rem 1.0rem;
    }

    .sticky-card .card-body {
        padding: .5rem 0px;
    }

    /* ----------------------------------------- */


    #modal__seatreservation>.modal-dialog {
        max-width: 970px;
    }

    /* ----------------------------------------- */

    .fare__selector_container .row.singlefare {
        margin-bottom: .15rem;
    }

    /* ----------------------------------------- */

    .segment__departure_airport,
    .segment__arrival_airport {
        font-size: 1.4rem;
    }

    .segment__departure_time,
    .segment__arrival_time {
        font-size: 1.2rem;
    }

    .segment__stops_traveltime {
        font-size: 1rem;
    }

    .segment__stops_totalstops {
        font-size: 0.85rem;
    }

    /* ----------------------------------------- */

    .segment__moreFlightTimes table th,
    .segment__moreFlightTimes table td,
    .segment__selectedFlightTimes table th,
    .segment__selectedFlightTimes table td {
        width: 14%;
        padding: .2rem .2rem;
        white-space: nowrap;
        font-size: 0.85rem;
    }

    /* ----------------------------------------- */

    .table-sm th,
    .table-sm td {
        padding: .2rem .3rem;
        font-size: 0.85rem;
    }

    /* ----------------------------------------- */

    .segment__moreFlightTimes table,
    .fare__FlightDetails table {
        font-size: 0.9rem;
        margin-bottom: 0;
        background-color: var(--base-bg-color-2, #f8f9fa);
    }

    .segment__moreFlightTimes table th,
    .segment__moreFlightTimes table td,
    .segment__selectedFlightTimes table th,
    .segment__selectedFlightTimes table td {
        padding: .2rem .5rem;
        background-color: rgba(255, 255, 255, .3);
    }

    .modal__flight_details .modal-body {
        font-size: 0.9rem;
    }

    /* ----------------------------------------- */

    .selectedBookingManagerTableRow {
        border: 2px solid grey !important;
        box-shadow: 0 0 3px .5px grey;
        background-color: #369ff51f !important;
    }

    .BookingDetailsModal {
        opacity: 0.9 !important;
    }

    .ancillaries svg {
        margin-right: 3px;
    }

    .title_ancillaries i {
        color: #fff;
        margin-right: 5px;
        font-size: .9rem;
    }

    #ancillaries__Content {
        border: solid 1px #dee2e6;
        background-color: var(--base-bg-color-2, #f8f9fa);
    }

    /* ----------------------------------------- */

    .fare__carrier_price {
        max-width: 250px;
    }

    .fare__carrier_price div.price {
        font-size: 1.1rem;
        line-height: 1.2;
        font-weight: 400;
    }

    .fare__carrier_price div.tax {
        font-size: .8rem;
        line-height: 1.1;
        font-weight: normal;
        color: #777;
    }

    .fare__single_fare_infobox {
        font-size: .9rem;
        padding: 0.35rem 0 .4rem 0;
    }

    /* ----------------------------------------- */

    #screen-bookingconfirmation .pax-details_item {
        margin-bottom: unset;
        border-bottom: none;
    }

}


/* Large devices (desktops, 992px and up)              */
/* *************************************************** */
@media (min-width: 992px) {
    .container {
        max-width: 98%;
    }

    /* .bm-menu-wrap {
            margin-left:-30px;
        } */

    .content-wrapper {
        padding: 1.5rem;
        background-color: rgba(0, 0, 0, 0.3);
        position: relative;
        z-index: 3;
    }

    /* ----------------------------------------- */


    #screen-query,
    #screen-farebooking,
    #screen-bookingconfirmation,
    #screen-faredisplay,
    #screen-bookingmanager {
        margin-top: 3rem;
    }


    /* ----------------------------------------- */

    #modal__upsell>.modal-dialog {
        display: table;
        min-width: 600px;
    }

    /* ----------------------------------------- */

    .fare__departure_airport,
    .fare__arrival_airport {
        font-size: 1.4rem;
        font-weight: normal;
    }

    .fare__carrier_price div.price {
        font-size: 1.2rem;
        line-height: 1.7;
    }

    /* ----------------------------------------- */

    #screen-bookingmanager .card-header {
        padding: .75rem 1.25rem;
    }

    #screen-bookingmanager .card-body {
        padding: 1.25rem;
    }

    /* ----------------------------------------- */

    #faredisplay__filter {
        width: 400px !important;
    }

    .bm-menu.card-header {
        border-radius: 0;
        padding: 20px 30px;
    }

    .bm-menu.card-body {
        padding: 20px 30px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */
/* *************************************************** */
@media (min-width: 1200px) {
    .xl-wrapper {
        width: 90%;
    }
}


/* XL devices                                          */
/* *************************************************** */
@media (min-width: 1500px) {
    .xl-wrapper {
        width: 90%;
        max-width: 1450px;
    }
}

/* Sort Function css */

.mainBox {
    display: flex;
}

.carouselUpsell {
    width: 100% !important;
}

.carouselUpsell .react-multiple-carousel__arrow--left {
    right: calc(1% + 1px) !important;
    /* top: 58% !important; */
    left: unset !important;
    opacity: 0.95 !important;
    /* background-color: #2196F3 !important; */
    min-height: 35px !important;
    min-width: 35px !important;
    /* border-radius: 0px !important; */
}

.carouselUpsell .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px) !important;
    opacity: 0.95 !important;
    /* background-color: #2196F3 !important; */
    min-height: 36px !important;
    min-width: 36px !important;
    /* border-radius: 0px !important; */
}

.upSellContainer {
    padding: 4px;
}

.sortBox {
    width: 35%;
    background-color: white;
    padding: 10px 10px 0px 10px;
    margin-bottom: 3px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
}

.sortBox p {
    margin: 0;
}

.sortBox strong {
    padding-bottom: 5px;
    font-weight: 600;
}

.activeCard {
    background-color: #2196f3;
    color: white;
}

.activeTR {
    border: "2px solid #c68305" !important;
    background: red !important;
}

.activeTab1 {
    background-color: #2196f3 !important;
    color: white !important;
}

.storedServicesContainer {
    margin-top: 16px;
}

/* tabel */

.dataGridTable1 .MuiDataGrid-columnHeaders {
    line-height: unset !important;
    min-width: 100% !important;
    max-width: 100% !important;
}

.dataGridTable1 .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnHeaderTitle {
    white-space: nowrap !important;
}

.dataGridTable1 .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces !important;
}


/* style to hide airport list */
.hideAirportList {
    display: none;
    /* background-color: red; */
}

.active {
    background-color: #2196f3 !important;
    color: white !important;
}

.modal-xl {
    --bs-modal-width: 1300px;
}

@media screen and (max-width: 1299px) {
    .modal-xl {
        --bs-modal-width: 1250px;
    }
}

.HotelList {
    font-family: sans-serif;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 45px !important;
}

.css-14lo706 {
    overflow: visible !important;
}

.css-14lo706>span {
    opacity: 7 !important;
}

.autoRefresh .css-qiwgdb {
    padding: 7.5px 14px;
}

.roomInput {
    border-radius: 5px !important;
}

.ratingText {
    color: #fff;
    border-radius: 10px;
    padding: 5px 15px;
    font-weight: 700;
    background-color: #029f02;
    font-size: 12px;
    /* position: relative;
    bottom: 25px;
    right: 15px; */
    display: inline-block;
}

.roomSection {
    position: absolute;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    min-width: 280px;
    border-radius: 5px;
    z-index: 1;
}

.filterBtn {
    opacity: 0.4;
}

.filterBtn:hover {
    opacity: 1;
}

.AdultValue {
    display: inline-block;
    margin: 0px 10px;
}

.circle-button {
    /* display: inline-block !important; */
    /* background-color: #007bff; */
    margin: 10px;
    color: #007bff;
    font-weight: bold;
}

.btnWidth {
    min-width: 180px;
}

.circle-button:hover {
    color: #424242;
}

.circle-buttonPlus {
    padding: 1px 8px;
}

.circle-buttonMinus {
    padding: 1px 9px;
}

.review {
    display: inline-block;
    border: 2px solid dodgerblue;
    padding: 5px 10px;
    border-radius: 5px;
}


.tabPan .nav .nav-link {
    color: black !important;
}

.tabPan .nav-tabs .nav-link {
    border: none;
}

.tabPan .nav-tabs .nav-link.active,
.tabPan .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border: none;
}

.tabPan .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    isolation: isolate;
    border: none;
}

.tabPan .nav .active {
    background-color: white !important;
    /* color: white !important; */
    color: #2196F3 !important;
    border-bottom: 2px solid #2196F3 !important;
    font-weight: 600 !important;
}

.tabPan .tabArea {
    background-color: white !important;
    color: #000 !important;
}

.rating-container {
    position: absolute;
    right: 60px;
    left: auto;
    width: 300px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 1;
}

.rating-line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.category {
    flex: 0 0 120px;
    font-weight: bold;
}

.rating-bar {
    flex: 1;
    background-color: lightgray;
    height: 10px;
    border-radius: 5px;
    margin: 0 10px;
}

.rating-progress {
    background-color: #2196F3;
    height: 100%;
    border-radius: 5px;
    width: 60%;
    /* Adjust the width as needed */
}

.rating-value {
    flex: 0 0 40px;
    font-weight: bold;
}


.reviews-tab-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.reviewBox {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.review-author {
    font-size: 18px;
    font-weight: bold;
}

.review-rating {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.review-rating span {
    color: #FFD700;
    margin-right: 5px;
}

.review-comment {
    font-size: 16px;
    line-height: 1.4;
}


.responsive-icon {
    width: 100%;
    height: 100px;
    /* Set the width to take up the full width of the container */
}

@media (max-width: 768px) {
    .responsive-icon {
        /* Define your responsive styles here */
        /* For example, you can adjust the height and opacity */
        height: 50%;
        opacity: 0.5;
    }
}

@media (max-width: 480px) {
    .responsive-icon {
        /* Adjust the styles for smaller screens */
        height: 30%;
        opacity: 0.8;
    }

    .widthSideBar {
        width: 100% !important;
    }
}

.priceTable tr td:first-child {
    width: 25%;
}

.priceTable tr th:not(:first-child) {
    text-align: right;
}

.priceTable tr td:not(:first-child) {
    cursor: pointer;
    text-align: right;
    width: 150px;
}

.priceTable tr td,
.priceTable tr th {
    font-weight: 600;
    font-size: 15px;
}

.priceTable tr,
.priceTable td {
    border-color: #7e7f80;
    border-style: solid;
    border-width: 1.5px;
}



.selected {
    background-color: #98d8ffe4 !important;
    font-weight: 700;
}

.sticky-container {
    position: sticky;
    top: 0;
    z-index: 100;
}

.sticky-card {
    background-color: #C2DDF8;
    /* Add any additional styles as needed */
}

.dNone {
    display: none;
}

.star {
    font-size: 24px;
    color: #F9AA00;
    text-shadow: 1px 1px 2px rgba(52, 52, 52, 0.345);
}

.checkButton {
    background: 'white';
    border-radius: '6px';
    margin: 1px;
}

@media (min-width: 1500px) {
    .search-div {
        width: 34% !important;
    }

}

@media (min-width: 1800px) {
    .search-div {
        width: 36% !important;
    }

}

.search-div svg,
.dateDiv svg,
.nightsDiv svg,
.roomDiv svg {
    width: 25px !important;
    height: 20px !important;
}

.search-div,
.dateDiv,
.nightsDiv,
.roomDiv {
    padding-right: 0px !important;
}

/* Loading Spinner */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 4px solid #ccc;
    animation: spin 2s linear infinite;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #3498db;
    border-top: 4px solid transparent;
    animation: spin 1s linear infinite;
}

.btnminusplus {
    width: 27px !important;
    height: 27px !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(min-width:600px) {
    .widthSideBar {
        max-width: 190px;
    }
}


@media(max-width:600px) {
    .companyLogo {
        max-width: 140px !important;
    }

    .card-body {
        margin-top: 20px;
    }

    .widthSideBar {
        width: 100% !important;

    }

    .fieldContainer {
        gap: 20px;
    }

    .input-group-lg>.form-control,
    .input-group-lg>.form-select,
    .input-group-lg>.input-group-text,
    .input-group-lg>.btn {
        padding: 0.5rem 1rem;
        font-size: 1rem !important;
        font-weight: 300;
        border-radius: 0.5rem;
    }

    .dateDiv {
        height: 48px;
    }
}

.dateText input {
    font-size: 20px !important;
}

#dateFont input {
    font-size: 20px !important;
}

.customerInfo {
    color: #000 !important;
    background-color: white !important;
}

.loadingStyle {
    position: absolute;
    top: 40%;
    left: 50%;
    margin: 0;
    background-color: #f0f0f0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 20px 0px;
}

.loader-container {
    text-align: center;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.customerInfos .form-label {
    margin-bottom: 0rem;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    text-align: center;
    /* Make sure it's above other content */
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.room .form-control:disabled,
.form-control[readonly],
.form-select:disabled {
    background-color: white !important;
    opacity: 1;
}

.hotelStatusBar {
    margin-top: 5rem;
    background-color: #f0f0f0;
    border-radius: 15px;
    position: relative;
    bottom: 0%;
}

.bdr {
    border-radius: 3px;
    overflow: hidden;
}

.dropdown-menu.show {
    display: block;
    position: absolute;
    right: 0px;
}

.iconFont {
    font-size: 35px;
    /* width: 180px; */
}

/* .dislikeIcon,
.likeIcon {
    padding: 6px 8px;
    color: dodgerblue;
    margin: 15px;
    display: inline-block;
    border: 1px solid none;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    cursor: pointer;
} */

/* .btnWidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px; 
    margin: 0 auto;
  } */

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    /* Adjust margin as needed */
}

.ellipticalButton {
    justify-content: end;
    width: 100px;
    /* padding: 5px 0px; */
    height: 40px;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    background: whitesmoke;
    color: #007bff;
    transition: background 0.3s;
    position: relative;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.likeSection,
.dislikeSection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
}

.likeIcon,
.dislikeIcon {
    font-size: 20px;
    /* color: #2196f3; */
    /* position: absolute; */
    transition: transform 0.2s;
}

.likeIcon {
    left: 10px;
}

.dislikeIcon {
    right: 10px;
}

.likeSection:hover {
    background: #64b5f6;
}

.dislikeSection:hover .dislikeIcon {
    color: white
}

.likeSection:hover .likeIcon {
    transform: scale(1.1);
}

.likeSection:hover .likeIcon {
    color: white
}

.dislikeSection:hover {
    background: #64b5f6;
    color: white;
}

.dislikeSection:hover .dislikeIcon {
    transform: scale(1.1);
}

.tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    padding: 4px 8px;
}


.likeIcon {
    /* Your existing styles */
    transition: transform 0.2s;
    /* color: white; */
    /* Add a transition for the animation */
}

.likeIcon.active {
    transform: scale(1.2);
    color: white;
    /* Apply the animation when the icon is active */
}

.hotelMap {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./images/map-with-destination.jpg');
    background-position: center center;
    background-size: cover;
}

.hotelMap h3 {
    font-weight: bold;
    font-size: 36px;
}

#square-input {
    width: 50px !important;
    height: 50px !important;
    padding: 0.5em !important;
    box-sizing: border-box !important;
    border: 1px solid #ff1b1b !important;
    border-radius: 5px !important;
}

.hotelCount {
    background-color: #fff;
    border-radius: 15px;
    position: fixed;
    bottom: 50px;
    padding: 10px;
}

.description p {
    margin-bottom: 2px !important;
}

.alreadyAdded {
    color: #00b7ff;
}

.dangerBg {
    background-color: #DC3545 !important;
}

select .fontChang {
    font-size: 1rem;
}

.btnStyle {
    border-radius: 0px 6px 6px 0px;
}

.btnStyle2 {
    border-radius: 6px 0px 0px 6px;
}

.autoRefresh .MuiOutlinedInput-notchedOutline {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.manageElementAlert {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.manageElementCancelActive {
    background-color: #fff !important;
    color: white !important;
}

.operatedByCss .popover-body {
    padding: 12px !important;
}

.operatedByCss .arrow {
    transform: translate(0px, 14px) !important;
}

.WL {
    padding: 1px 4px;
    border-radius: 4px;
    border: 1.5px solid gray;
    background-color: #ED1c24;
    color: white;
    font-size: small;
    display: flex;
    align-items: center;
    height: 26px;
}


/*  media query */

@media ((min-width: 992px) and (max-width:1280px)) {
    .search-div {
        flex: 0 0 auto;
        width: 25%;
    }

    .roomDiv {
        width: 20%;
    }

    .nightsDiv {
        width: 15%;
    }

    .dateDiv {
        width: 20%;
    }
}

@media (min-width: 1200px) {
    .search-div {
        width: 30% !important;
    }

    .dateDiv {
        width: 20% !important;
    }

    .nightsDiv {
        width: 15% !important;
    }

    .roomDiv {
        width: 20% !important;
    }

    /* .btnDiv {
        width: 7% !important;
    } */

}

.circle {
    width: 12.5px;
    height: 12.5px;
    background-color: #999999;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 8px;
    font-weight: 500;
}

.d-dropdown {
    width: 100%;
    margin: 0 !important;
}

/* Marquee  */
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
}

.marquee-text {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 20s linear infinite;
    color: red;
    backface-visibility: hidden;
    /* Improve text rendering during animation */
}

@keyframes marquee {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(-100%, 0, 0);
    }
}

.disabled-div {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}

.specialDiscount .fa-stack {
    display: inline-block;
    vertical-align: middle;
    height: 1em !important;
    position: relative;
    width: 1.5em !important;
}

.modal-overlay {
    position: fixed;
    top: 50%;
    left: 40%;
    width: 20%;
    /* height: 100%; */
    background: rgba(0, 0, 0, 0.5);
    display: block;
    justify-content: center;
    align-items: center;
    z-index: 999;
    -webkit-box-shadow: 12px 9px 13px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 12px 9px 13px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 12px 9px 13px -10px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}